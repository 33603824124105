<template>
  <div id="elv-twitter-sign"></div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const handleCredentialResponse = (response: any) => {
  console.log(`Encoded JWT ID token: ${response.credential}`)
}

onMounted(() => {
  nextTick(() => {
    window.google.accounts.id.initialize({
      client_id: import.meta.env.VITE_GOOGLE_KEY,
      callback: handleCredentialResponse
    })
    window.google.accounts.id.renderButton(document.getElementById('elv-twitter-sign'), {
      theme: 'outline',
      size: 'large',
      type: 'standard',
      shape: 'rectangular',
      logo_alignment: 'center',
      width: '315',
      text: 'continue_with',
      locale: locale.value
    })
    window.google.accounts.id.prompt()
  })
})
</script>

<style lang="scss" scoped>
#elv-twitter-sign {
  margin-bottom: 10px;
}
</style>
